import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { Media } from "../../utils/Media"

//Gatsby imports
import { graphql } from "gatsby"

//React components imports
import Layout from "../../components/Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import ProductItem from "./ProductItem"

//Styled components imports
import Title from "./Title"
import Description from "./Description"
import Wrapper from "./Wrapper"

const ProductsPage = ({ data, location, pageContext }) => {
  const intl = useIntl()

  const getData = () => {
    if (intl.locale === "en") {
      return pageContext.dataEn
    } else {
      return pageContext.dataCs
    }
  }

  const localeData = getData()

  return (
    <Layout subtitle={localeData.title.replace(/.*\//, "")}>
      <Breadcrumbs
        data={{
          path: "/produkty" + location.pathname.replace("/en", ""),
          breadEnd: localeData.title.replace(/.*\//, ""),
        }}
      />
      <Media greaterThanOrEqual="tablet">
        <Title marginBottom="8px">{localeData.title.replace(/.*\//, "")}</Title>
      </Media>
      <Description>{data.shopifyCollection.description}</Description>
      <Wrapper>
        {data.shopifyCollection.products.map(product => {
          return (
            <ProductItem
              data={product}
              title={
                localeData.products.edges.find(({ node }) => {
                  return node.handle === product.handle
                })?.node.title
              }
              key={product.handle}
            />
          )
        })}
      </Wrapper>
    </Layout>
  )
}

export default ProductsPage

export const query = graphql`
  query ProductsQuery($shopifyId: String) {
    shopifyCollection(shopifyId: { eq: $shopifyId }) {
      title
      handle
      description
      products {
        handle
        title
        images {
          gatsbyImageData(width: 450)
        }
        priceRangeV2 {
          minVariantPrice {
            amount
          }
        }
      }
    }
  }
`
