import styled from "styled-components"

const Description = styled.div`
  text-align: center;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 1024px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: left;
    font-size: 20px;
    padding: 15px 0;
  }
`

export default Description
